.App {
  display: flex;
  flex-flow: column;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
  margin-left: 15px;
  margin-right: 15px;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
}

.App-header {
}

.App-link {
  color: #61dafb;
}

.App-content {
  background-color: #FFFFFF;
  color: #000000;
  width: calc(300px + 20vmin);
}

.App-footer {
  margin-top: 60px;
  margin-bottom: 10px;
}

.App-footer > div {
  flex: 1;
  flex-direction: row;
  justify-content: center;
}

.App-footer > div > a {
  margin-left: 15px;
  margin-right: 15px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
